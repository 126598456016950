<template>
  <div class="row">
    <div class="col-lg-12 col-md-8 col-sm-3">
      <KTPortlet>
        <template v-slot:body>
          <b-form class="kt-form" @submit.stop.prevent="onSubmit">
            <div class="text-center">
              <h3>{{ $t("AUTH.FORGOT.RESET_PASSWORD") }}</h3>
            </div>
            <br />
            <div role="alert" class="alert alert-dark">
              <div class="alert-text">
                <h6>{{ $t("AUTH.FORGOT.RESET_DESC") }}</h6>
              </div>
            </div>
            <div
              role="alert"
              v-if="errors.length"
              v-bind:class="{ show: errors.length }"
              class="alert fade alert-danger"
            >
              <div class="alert-text">
                <ul v-for="(error, i) in errors" :key="i">
                  <li>{{ error }}</li>
                </ul>
              </div>
            </div>
            <b-overlay :show="uploading" variant="transparent" rounded="sm">
              <b-row>
                <b-col>
                  <div role="group">
                    <label> {{ $t("PROFILE.EMAIL_ADDRESS") }} </label>
                    <b-form-input
                      id="email_address"
                      name="email_address"
                      type="email"
                      v-model="$v.form.email_address.$model"
                      :state="validateState('email_address')"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-2-live-feedback">
                      {{
                        $t("VALIDATION.REQUIRED", {
                          name: $t("PROFILE.EMAIL_ADDRESS")
                        })
                      }}
                    </b-form-invalid-feedback>
                  </div>
                </b-col>
              </b-row>
              <b-row class="kt-margin-t-15">
                <b-col>
                  <b-form-group
                    id="login-input-group-1"
                    label=""
                    label-for="login-input-1"
                  >
                    <label> {{ $t("AUTH.INPUT.PASSWORD") }} </label>
                    <b-form-input
                      id="password"
                      name="password"
                      type="password"
                      v-model="$v.form.password.$model"
                      :state="validateState('password')"
                      aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-2-live-feedback">
                      {{
                        $t("VALIDATION.REQUIRED", {
                          name: $t("AUTH.INPUT.PASSWORD")
                        })
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="login-input-group-2"
                    label=""
                    label-for="login-input-2"
                  >
                    <label>
                      {{ $t("AUTH.INPUT.PASSWORD_CONFIRMATION") }}
                    </label>
                    <b-form-input
                      id="password_confirmation"
                      name="password_confirmation"
                      type="password"
                      v-model="$v.form.password_confirmation.$model"
                      :state="validateState('password_confirmation')"
                      aria-describedby="input-2-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-2-live-feedback">
                      {{
                        $t("VALIDATION.REQUIRED", {
                          name: $t("AUTH.INPUT.PASSWORD_CONFIRMATION")
                        })
                      }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="kt-margin-t-5">
                  <b-button
                    type="submit"
                    id="kt_submit"
                    class="btn btn-primary btn-elevate kt-login__btn-primary"
                  >
                    {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
                  </b-button>
                </b-col>
                <b-col class="kt-margin-t-15">
                  <router-link
                    to="/login"
                    class="kt-link kt-login__link-forgot"
                  >
                    {{ $t("AUTH.FORGOT.RETURN_TO_LOGIN") }}
                  </router-link>
                </b-col>
              </b-row>
            </b-overlay>
          </b-form>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";

import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import ApiService from "@/common/api.service";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      form: {
        email_address: null,
        password: null,
        password_confirmation: null
      },
      errors: [],
      message: null,
      uploading: false,
      token: null
    };
  },
  components: {
    KTPortlet
  },
  validations: {
    form: {
      email_address: {
        email,
        required
      },
      password: {
        required,
        minLength: minLength(6)
      },
      password_confirmation: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
        password_confirmation: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.errors = [];
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const email_address = this.$v.form.email_address.$model;
      const password = this.$v.form.password.$model;
      const password_confirmation = this.$v.form.password_confirmation.$model;
      const token = this.$route.params.token;
      this.uploading = true;
      ApiService.put("reset-password", {
        email_address: email_address,
        password: password,
        password_confirmation: password_confirmation,
        token: token
      })
        .then(result => {
          this.uploading = false;
          this.$router.push({
            name: "login",
            params: { message: result.data.message }
          });
        })
        .catch(({ response }) => {
          this.uploading = false;
          this.errors = response.data.error;
        });
    }
  }
};
</script>
